import { rgba } from "@Utils/style-util";
import { Img } from "react-image";
import ReactSelect from "react-select";
import { TabList } from "react-tabs";
import styled, { css } from "styled-components";
import Link from "./Link";

export const Main = styled.main`
  margin-top: 81px;
  @media (max-width: 991.98px) {
    margin-top: 66px;
  }
  ${({ quiz }) =>
    quiz &&
    css`
      padding: 90px 0 114px;
    `}
`;
export const StyledSection = styled.section`
  margin-bottom: 40px;
  &:first-of-type {
    margin-top: 30px;
  }
`;
export const SectionTitle = styled.h2`
  font-size: 3.2rem;
  position: relative;
  font-weight: 600;
  /* margin-bottom: 20px; */
  text-align: ${({ alignLeft }) => (alignLeft ? "left" : "center")};
  /* &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.gray};
  } */
  @media (max-width: 767.98px) {
    font-size: 2.6rem;
  }
`;
export const SectionDesc = styled.p`
  font-size: 2rem;
  max-width: 75%;
  text-align: center;
  margin: ${({ mtTop }) => (mtTop ? mtTop : "0")}px auto 0;
  color: #666;
  &:last-of-type {
    margin-bottom: 60px;
  }
  @media (max-width: 767.98px) {
    font-size: 1.6rem;
    margin-bottom: 30px;
    max-width: 90%;
  }
`;

export const UserProjectTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 15px;
`;
export const PackageDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .has-coupon {
    text-decoration: line-through;
    color: #999;
  }
  .with-coupon {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brandColor};
  }
  @media (max-width: 575.98px) {
    display: block;
    & > div {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
export const UserSection = styled.section`
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 30px;
`;
export const DesignBoardSection = styled.section`
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  padding-top: 46px;
  &:last-of-type {
    border-bottom: none;
  }
`;
export const Divider = styled.div`
  border-bottom: 1px solid whitesmoke;
  margin-bottom: 15px;
  &:last-of-type {
    display: none;
  }
`;
export const StyledTabList = styled(TabList)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  li {
    padding: 10px 20px;
    border: 1px solid whitesmoke;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s ease background-color, 0.3s ease color;
    &.react-tabs__tab--selected {
      background-color: ${({ theme }) => theme.colors.brandColor};
      color: white;
    }
  }
`;
export const BoardImage = styled.div`
  height: auto;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
  background-color: #fff;
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const StyledImg = styled(Img)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
`;
export const DownloadBoardImage = styled.a`
  display: flex;
  min-width: 135px;
  max-width: 135px;
  min-height: 38px;
  max-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 1;
  transition: 0.3s ease box-shadow, 0.6s ease background-color;
  border-radius: 2px;
  border: solid 0.6px #f67a64;
  background-color: #fff;
  color: #f67a64;
  font-size: 1.4rem;
  font-weight: 600;
  &:hover {
    box-shadow: 0 0 10px ${({ theme }) => rgba(theme.colors.textColor, 0.2)};
  }
  &:active {
    background-color: #af071b;
  }
  .icon-long-arrow-right,
  .icon-long-arrow-left {
    transform: rotate(90deg);
    font-size: 1rem;
    border-right: 1px solid red;
    padding-right: 2px;
    margin-right: 10px;
  }
`;
export const SaveSelections = styled.div`
  margin-top: 30px;
  cursor: pointer;
  appearance: none;
  user-select: none;
  outline: none;
  border: none;
  display: block;
  background-color: ${({ isActive, theme }) => (isActive ? "#999" : theme.colors.brandColor)};
  color: #fff;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
  transition: 0.3s ease background-color;
  &:active {
    background-color: #af071b;
  }
`;
export const AccountLink = styled(Link)`
  font-size: 1.8rem;
  font-weight: 600;
  font-weight: 600;
  padding-bottom: ${({ isRTL }) => (isRTL ? "10px" : "17px")};
  white-space: nowrap;
  margin-right: 20px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.brandColor : theme.colors.textColor)};
  border-bottom: 2px solid ${({ theme, isActive }) => (isActive ? theme.colors.brandColor : "transparent")};
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid whitesmoke;
  padding: 30px 0;
  &:first-of-type {
    margin-top: -30px;
  }
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 60px;
  }
`;
export const ContactTitle = styled.h4`
  font-size: 2rem;
  margin-right: 15px;
`;
export const ContactNum = styled.a`
  font-size: 1.8rem;
  color: #666;
  font-weight: 600;
  line-height: 1;
  &:hover {
    text-decoration: underline;
  }
  ${({ isRTL }) =>
    isRTL &&
    css`
      direction: ltr /* rtl:ignore */;
    `}
`;
export const StyledSelect = styled(ReactSelect)`
  &.has-error {
    .common__control {
      border-color: #f2423e;
    }
  }
`;
export const AddressWrapper = styled.div`
  margin-bottom: 60px;
`;
export const Address = styled.address`
  text-align: left;
  font-size: 2rem;
  margin-bottom: 30px;
  font-style: normal;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
  position: relative;
  /* &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 20px;
    background-color: #666;
  } */
`;
export const AddressCost = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: #707070;
  font-size: 2rem;
`;
export const AddressCity = styled.h3`
  font-size: 2.4rem;
`;
export const AddressDesc = styled.p`
  font-size: 2.2.rem;
  color: #666;
  margin-bottom: 20px;
`;
export const AddressNumber = styled.p`
  font-size: 1.8rem;
  margin-top: 15px;
  span {
    color: #666;
    display: inline-block;
    margin-left: 10px;
  }
`;
export const AddressDelivery = styled.p`
  font-size: 1.8rem;
  color: #707070;
  font-weight: 300;
  margin-bottom: 15px;
`;
export const AnotherAddress = styled.h2`
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 30px;
`;
export const BoardDate = styled.time`
  font-size: 1.6rem;
  color: #999;
`;
